import XperMeetLib from 'xpermeet-lib';
import { consoleLog, consoleError } from 'xpermeet-lib/src/utils/logger';

import { DEVICES, MEDIA_DEVICE_STATE, CHANGE_DEVICE_TYPE } from '@/constants/devices';
import storage from '@/services/storage';
import { getLocalSelectedDevices, checkDevicesDifferences } from '@/helpers/devices';
import { TOAST_POSITION, TOAST_TYPE } from '@/constants/toast';

export default {
  namespaced: true,
  state: {
    [DEVICES.kinds.MICROPHONE]: [],
    [DEVICES.kinds.CAMERA]: [],
    [DEVICES.kinds.SPEAKER]: [],
    cameraState: MEDIA_DEVICE_STATE.PROMPT,
    microphoneState: MEDIA_DEVICE_STATE.PROMPT,
    speakerState: MEDIA_DEVICE_STATE.PROMPT,
    selectedCameraId: '',
    selectedMicrophoneId: '',
    selectedSpeakerId: '',
    noiseCanceling: true,
    showNewDeviceAddedModal: false,
    addedDevices: []
  },
  getters: {
    getMicrophones(state) {
      return state[DEVICES.kinds.MICROPHONE].filter(d => d.deviceId);
    },
    getCameras(state) {
      return state[DEVICES.kinds.CAMERA].filter(d => d.deviceId);
    },
    getSpeakers(state) {
      return state[DEVICES.kinds.SPEAKER]
        .map(device => {
          return {
            ...device,
            label: device.label || 'Default Speaker',
            deviceId: device.deviceId || 'default',
            kind: DEVICES.kinds.SPEAKER,
          };
        })
        .filter(d => d.deviceId);
    },
    getSelectedMicrophone(state, getters) {
      return getters.getMicrophones.find(d => d.deviceId === state.selectedMicrophoneId) || (getters.getMicrophones.length ? getters.getMicrophones[0] : null);
    },
    getSelectedMicrophoneId(state) {
      return state.selectedMicrophoneId;
    },
    getSelectedCamera(state, getters) {
      return getters.getCameras.find(d => d.deviceId === state.selectedCameraId) || (getters.getCameras.length ? getters.getCameras[0] : null);
    },
    getSelectedCameraId(state) {
      return state.selectedCameraId;
    },
    getSelectedSpeaker(state, getters) {
      return getters.getSpeakers.find(d => d.deviceId === state.selectedSpeakerId) || (getters.getSpeakers.length ? getters.getSpeakers[0] : null);
    },
    getSelectedSpeakerId(state) {
      return state.selectedSpeakerId;
    },
    hasCamera(state, getters) {
      return state.cameraState !== MEDIA_DEVICE_STATE.NOT_FOUND_ERROR && !!getters.getCameras.length;
    },
    hasMicrophone(state, getters) {
      return state.microphoneState !== MEDIA_DEVICE_STATE.NOT_FOUND_ERROR && !!getters.getMicrophones.length;
    },
    hasSpeaker(state, getters) {
      return state.speakerState !== MEDIA_DEVICE_STATE.NOT_FOUND_ERROR && !!getters.getSpeakers.length;
    },
    cameraAllowed(state) {
      return state.cameraState === MEDIA_DEVICE_STATE.ALLOWED;
    },
    microphoneAllowed(state) {
      return state.microphoneState === MEDIA_DEVICE_STATE.ALLOWED;
    },
    isNoiceCancelationEnabled(state) {
      return state.noiseCanceling;
    },
    getFirstAddedDevice(state) {
      return state.addedDevices[0];
    }
  },
  mutations: {
    SET_NOICE_CANCELING(state, value) {
      state.noiseCanceling = value;
    },
    SET_DEVICE(state, payload) {
      let { deviceId } = payload;
      const { kind } = payload;
      if (!state[kind].find(d => d.deviceId === deviceId)) {
        const selectedDeviceId = storage.getItem(`selected_${kind}`);
        if (kind === DEVICES.kinds.SPEAKER && !deviceId) {
          deviceId = 'default';
        }
        let selected = false;
        if (selectedDeviceId === deviceId) {
          selected = true;
        } else if (!selectedDeviceId && deviceId === 'default') {
          selected = true;
        }
        if (selected) {
          switch (kind) {
            case DEVICES.kinds.MICROPHONE:
              state.selectedMicrophoneId = deviceId;
              break;
            case DEVICES.kinds.CAMERA:
              state.selectedCameraId = deviceId;
              break;
            case DEVICES.kinds.SPEAKER:
              state.selectedSpeakerId = deviceId;
              break;
          }
        }
        state[kind].push(payload);
      }
    },
    SELECT_DEVICE(state, payload) {
      if (!payload) {
        return;
      }
      switch (payload.kind) {
        case DEVICES.kinds.MICROPHONE:
          state.selectedMicrophoneId = payload.deviceId;
          break;
        case DEVICES.kinds.CAMERA:
          state.selectedCameraId = payload.deviceId;
          break;
        case DEVICES.kinds.SPEAKER:
          state.selectedSpeakerId = payload.deviceId;
          break;
      }

      storage.setItem(`selected_${payload.kind}`, payload.deviceId);
    },
    SET_DEVICE_STATE(state, payload) {
      const { type, value } = payload;
      if (type === 'camera') {
        state.cameraState = value;
      } else if (type === 'microphone') {
        state.microphoneState = value;
      }
    },
    CLEAR_DEVICES(state) {
      state[DEVICES.kinds.MICROPHONE] = [];
      state[DEVICES.kinds.CAMERA] = [];
      state[DEVICES.kinds.SPEAKER] = [];
    },
    SELECT_CAMERA(state, payload) {
      if (state[DEVICES.kinds.CAMERA].some(d => d.deviceId === payload)) {
        state.selectedCameraId = payload;
        storage.setItem('selected_videoinput', payload);
      } else {
        storage.removeItem('selected_videoinput');
      }
    },
    SELECT_MICROPHONE(state, payload) {
      if (state[DEVICES.kinds.MICROPHONE].some(d => d.deviceId === payload)) {
        state.selectedMicrophoneId = payload;
        storage.setItem('selected_audioinput', payload);
      } else {
        storage.removeItem('selected_audioinput');
      }
    },
    SELECT_SPEAKER(state, payload) {
      if (state[DEVICES.kinds.SPEAKER].some(d => d.deviceId === payload)) {
        state.selectedSpeakerId = payload;
        storage.setItem('selected_audiooutput', payload);
      } else {
        storage.removeItem('selected_audiooutput');
      }
    },
    SET_ADDED_DEVICE(state, payload) {
      const devices = state[payload.kind].filter(d => d.deviceId);
      const hasDevice = devices.find(device => device.deviceId === payload.deviceId);
      if (hasDevice && !state.addedDevices.find(device => (device.deviceId === payload.deviceId) && device.kind === payload.kind)) {
        state.addedDevices.push(payload);
        if (!state.showNewDeviceAddedModal) {
          state.showNewDeviceAddedModal = true;
        }
      }
    },
    REMOVE_ADDED_DEVICE(state, payload) {
      const index = state.addedDevices.findIndex(device => (device.deviceId === payload.deviceId) && device.kind === payload.kind);
      if (index > -1) {
        state.addedDevices.splice(index, 1);
      }
    },
    SET_SHOW_NEW_DEVICE_ADDED_MODAL(state, payload) {
      state.showNewDeviceAddedModal = payload;
    }
  },
  actions: {
    fetchDevices({ commit }) {
      return new Promise(resolve => {
        commit('CLEAR_DEVICES');
        XperMeetLib.mediaDevices.enumerateDevices(devices => {
          devices.forEach(d => commit('SET_DEVICE', d));

          const selectedDevices = getLocalSelectedDevices();

          commit('SELECT_CAMERA', selectedDevices.videoInput);
          commit('SELECT_MICROPHONE', selectedDevices.audioInput);
          commit('SELECT_SPEAKER', selectedDevices.audioOutput);
          resolve();
        });
      });
    },
    changeMicrophoneById({ dispatch, getters }, payload) {
      const device = getters.getMicrophones.find(d => d.deviceId === payload);
      dispatch('changeDevice', device);
    },
    changeCameraById({ dispatch, getters }, payload) {
      if (getters.getSelectedCameraId !== payload) {
        const device = getters.getCameras.find(d => d.deviceId === payload);
        dispatch('changeDevice', device);
      }
    },
    changeSpeakerById({ dispatch, getters }, payload) {
      const device = getters.getSpeakers.find(d => d.deviceId === payload);
      dispatch('changeDevice', device);
    },
    changeDevice({ commit, dispatch, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload) {
          return resolve();
        }

        const deviceType = DEVICES.types[payload.kind];

        if (deviceType === 'speaker') {
          XperMeetLib.conference.localUser.setAudioOutputDevice(payload.deviceId);
          commit('SELECT_DEVICE', payload);
          XperMeetLib.mediaDevices.setDefaultDevices(getLocalSelectedDevices());
          return;
        }

        if (deviceType === 'video') {
          const localUser = rootGetters['Conference/getLocalUser'];
          if (localUser.videoMutedByModerator || localUser.videoMuted) {
            dispatch('Notification/showToastNotification', { body: 'changeCameraError', config: { position: TOAST_POSITION.LEFT_BOTTOM, type: TOAST_TYPE.ERROR } }, { root: true });
            return;
          }
        }

        XperMeetLib.conference.localUser
          .switchLocalTrack(deviceType, payload.deviceId)
          .then(() => {
            consoleLog('Devices is changed', payload);
            commit('SELECT_DEVICE', payload);
            XperMeetLib.mediaDevices.setDefaultDevices(getLocalSelectedDevices());
            resolve(true);
          })
          .catch(err => {
            consoleError(err);
            reject(err);
          });
      });
    },
    //eslint-disable-next-line
    handleEvents({ commit, state, dispatch, rootGetters, getters }, payload) {
      XperMeetLib.mediaDevices.on('DeviceListChanged', devices => {
        const oldMicDevices = getters.getMicrophones;
        const oldVidDevices = getters.getCameras;
        const oldSpeakerDevices = getters.getSpeakers;
        const videoDevices = devices.filter(d => d.kind === 'videoinput');
        const micDevices = devices.filter(d => d.kind === 'audioinput');
        const speakerDevices = devices.filter(d => d.kind === 'audiooutput');
        const hasMicChange = checkDevicesDifferences(oldMicDevices, micDevices);
        const hasCameraChange = checkDevicesDifferences(oldVidDevices, videoDevices);
        const hasSpeakerChange = checkDevicesDifferences(oldSpeakerDevices, speakerDevices);

        commit('CLEAR_DEVICES');
        devices.forEach(d => commit('SET_DEVICE', d));
        const localUser = rootGetters['Conference/getLocalUser'];
        if (speakerDevices.length > 0 && hasSpeakerChange?.device?.length === 1 && hasSpeakerChange.changeType === CHANGE_DEVICE_TYPE.ADDED) {
          commit('SET_ADDED_DEVICE', hasSpeakerChange.device[0]);
        }
        if (videoDevices.length > 0 && hasCameraChange?.device?.length === 1) {
          XperMeetLib.conference.localUser.setLocalParticipantProperty('hasCamera', 'true');
          navigator.permissions.query({ name: 'camera' }).then(res => {
            if (res.state === 'granted') {
              commit('SET_DEVICE_STATE', { type: 'camera', value: MEDIA_DEVICE_STATE.ALLOWED });
            }
          });


          const prevMuteState = localUser.videoMuted;
          if (hasCameraChange.changeType === CHANGE_DEVICE_TYPE.REMOVED) {
            if (!prevMuteState) {
              dispatch('changeDevice', videoDevices[0]);
              dispatch('Conference/setVideoMute', true, { root: true });
              setTimeout(() => {
                dispatch('Conference/setVideoMute', false, { root: true });

              }, 500)
            }
          } else {
            if (!prevMuteState && hasCameraChange.device[0].deviceId === getters.getSelectedCameraId && getters.getCameras.length > 1) {
              dispatch('changeDevice', getters.getCameras[0]);
            }
            commit('SET_ADDED_DEVICE', hasCameraChange.device[0]);
          }

        } else if (videoDevices.length === 0) {
          commit('Conference/UPDATE_USER', { userId: localUser.id, data: { hasCamera: false, videoMuted: true } }, { root: true });
          XperMeetLib.conference.localUser.setLocalParticipantProperty('hasCamera', 'false');
          dispatch('Conference/setVideoMute', true, { root: true });
        }

        if (micDevices.length > 0 && hasMicChange?.device?.length === 1) {
          if (hasMicChange.changeType === CHANGE_DEVICE_TYPE.REMOVED) {
            dispatch('changeDevice', micDevices[micDevices.length - 1]).then(() => {
              setTimeout(() => {
                dispatch('changeDevice', micDevices[0]);
                if (!localUser.audioMutedByModerator) {
                  const prevMuteState = localUser.audioMuted;
                  dispatch('Conference/setAudioMute', prevMuteState, { root: true });
                }
              }, 300);
            });
          } else {
            commit('SET_ADDED_DEVICE', hasMicChange.device[0]);
          }

        } else if (micDevices.length === 0) {
          dispatch('Conference/setAudioMute', true, { root: true });
        }

      });
    },
    setNoiceCancelationIsEnabled({ commit }, payload) {
      XperMeetLib.conference.localUser.setNoiseSuppressionEnabled(payload).then(() => {
        commit('SET_NOICE_CANCELING', payload);
      });
    },
  },
};
