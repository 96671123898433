import Vue from 'vue';
import XperMeetLib, { DEFAULT_ROOM_CONFIG, ERROR_WITH_CODES } from 'xpermeet-lib';
import XperdriveLib from 'xperdrive-lib';
import axios from 'axios';
import { consoleError, consoleLog, consoleWarning } from '@/helpers/logger';
import storage from '@/services/storage';
import { getLocalSelectedDevices } from '@/helpers/devices';
import ManageService from '@/services/manage';
import i18n from '../../i18n/index';
import { parseJwt } from '@/helpers/token';
import router from '@/router';
import ENUMS, { NOTIFICATION_SOUND_TYPES } from '@/constants/ENUMS';
import { mimeTypeToExt, urlToFile } from '@/helpers/file';
import { changeRoles, ROLE_ADMIN, ROLE_PARTICIPANT, setPackagePermissions } from '@/services/ability';
import { ONPREMISE_FEATURES } from '@/constants/features';
import { generateRoomConfig } from '@/helpers/conference';
import { TOAST_POSITION, TOAST_TYPE } from '@/constants/toast';
import { createATagAndClick } from '@/helpers/create-element';
import { getServerConfig } from '@/services/config';
import { isMobile } from '@/helpers/detect-browser';

const {
  GLOBAL_ENUM,
  DEFAULT_PAGE_SIZE,
  VIDEO_QUALITY_LEVELS,
  RoomConfigEnums,
  CHAT_NOTIFICATION_TYPES,
  USER_CONNECTION_STATUS,
  ROOM_NOTIFICATION_TYPES,
  NOTIFICATION_TYPES,
  UI_ERROR_CODES,
} = ENUMS;

const User = ({ id, type = 'remote', displayName, role }) => ({
  id,
  type,
  displayName: displayName || `User ${id}`,
  tracks: [],
  role: role || 'participant',
  audioMuted: false,
  audioMutedByModerator: false,
  videoMuted: false,
  videoMutedByModerator: false,
  screenSharing: false,
  handsUp: false,
  ARTrack: false,
  hasCamera: true,
  hasMicrophone: true,
  localUserE2ee: false,
  localRecording: false,
  authenticated: false,
});

const initialState = () => {
  return {
    /* Room config */
    password: null,
    locked: false,
    roomConfig: DEFAULT_ROOM_CONFIG,
    name: null,
    roomName: null,
    meetingId: null,
    recordingStatus: XperMeetLib.remoteRecordingManager.isActiveSessionExists() ? GLOBAL_ENUM.RECORDING_STATUS.START : GLOBAL_ENUM.RECORDING_STATUS.STOP,
    remoteRecords: [],
    remoteRecordStartedAt: null,
    activePage: 1,
    xperMeetInitialized: false,
    conferenceJoined: false,
    dominantUserId: null,
    currentSpeakingUserId: null,
    users: [],
    messages: [],
    showChatScreen: false,
    isPrivateRoom: false,
    activeRightMenuTab: 'chat',
    waitingForTheHost: false,
    showConferencePasswordModal: false,
    showProfileModal: false,
    e2eePromptModal: false,
    showDialInModal: false,
    clearQueryParams: false,
    speakerMuted: false,
    sendPrivateMessageTo: null,
    allAudioMuted: false,
    allVideoMuted: false,
    showLogoutConferenceModal: null,
    showFileShareModal: false,
    showSharedFiles: false,
    files: [],
    showWhiteBoardModal: false,
    screenSharingUserList: [],
    createdTimestamp: null,
    layoutType: 'dynamic', // dynamic | grid
    e2eeMasterPassword: null, //local user e2ee master password
    showE2eePromptModal: false,
    smartCardAdapterEnabled: false,
    isJibri: false,
    sharedVideo: {
      value: null,
      status: 'stop', // "start" | "pause" | "playing" | "stop"
      from: null, // userId
      time: '0',
      volume: '100',
      muted: 'true',
      provider: 'youtube',
    },
    pageSize: DEFAULT_PAGE_SIZE,
    preferredVideoQuality: window.customerConfig?.system?.preferredVideoQuality || VIDEO_QUALITY_LEVELS.ULTRA,
    conferenceMaxUserReached: false,
    manageUser: null,
    arFileList: [],
    isChatScrolled: { conference: false, webinar: false },
    serverTime: null,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_IS_PRIVATE_ROOM(state, payload) {
      state.isPrivateRoom = payload;
    },
    SET_AR_FILE_LIST(state, payload) {
      state.arFileList = payload;
    },
    SET_ACTIVE_PAGE(state, payload) {
      state.activePage = payload;
    },
    SET_JIBRI(state, payload) {
      state.isJibri = payload;
    },
    SET_ROOM_NAME(state, payload) {
      state.roomName = payload;
    },
    SET_MEETING_ID(state, payload) {
      state.meetingId = payload;
    },
    SET_CONFERENCE_CREATED_TIMESTAMP(state, payload) {
      state.createdTimestamp = payload;
    },
    SET_SMART_CARD_ADAPTER_ENABLED(state, payload) {
      state.smartCardAdapterEnabled = payload;
    },
    SET_SHOW_E2EE_MODAL(state, payload) {
      state.showE2eePromptModal = payload;
    },
    SET_RECORDING_STATUS(state, payload) {
      state.recordingStatus = payload;
    },
    SET_REMOTE_RECORD_START_TIME(state, payload) {
      state.remoteRecordStartedAt = payload;
    },
    SET_FILES(state, payload) {
      state.files = payload;
    },
    ADD_FILE(state, payload) {
      state.files.push(payload);
    },
    SET_CONFERENCE_PASSWORD(state, payload) {
      state.password = payload;
    },
    SET_DOMINANT_USER_ID(state, payload) {
      state.dominantUserId = payload;
    },
    SET_CURRENT_SPEAKING_USER_ID(state, payload) {
      state.currentSpeakingUserId = payload;
    },
    TOGGLE_PROFILE_MODAL(state, open) {
      state.showProfileModal = open !== undefined ? open : !state.showProfileModal;
    },
    TOGGLE_DIAL_IN_MODAL(state, open) {
      state.showDialInModal = open !== undefined ? open : !state.showDialInModal;
    },
    TOGGLE_WHITE_BOARD(state) {
      state.showWhiteBoardModal = !state.showWhiteBoardModal;
    },
    SET_FULL_SCREEN(state, { userId, fullScreen }) {
      state.users = state.users.map(u => {
        if (u.id === userId) {
          u.fullScreen = fullScreen;
        } else {
          u.fullScreen = false;
        }
        return u;
      });
    },
    SET_XPER_MEET_INITIALIZE(state, payload) {
      state.xperMeetInitialized = payload;
    },
    SET_ROOM_CONFIG(state, payload) {
      Vue.set(state, 'roomConfig', payload);
    },
    ADD_USER_TRACK(state, payload) {
      const { userId, track } = payload;
      const user = state.users.find(u => u.id === userId);
      if (user) {
        if (track.getType() === 'audio') {
          Vue.set(user.tracks, 0, track);
        } else {
          Vue.set(user.tracks, 1, track);
        }
      }
    },
    REMOVE_USER_TRACK(state, payload) {
      const { userId, track } = payload;
      const user = state.users.find(u => u.id === userId);
      if (user) {
        if (track.getType() === 'audio') {
          Vue.delete(user.tracks, 0);
        } else {
          Vue.delete(user.tracks, 1);
        }
      }
    },
    ADD_USER(state, payload) {
      if (!state.users.find(u => u.id === payload.id)) {
        state.users.push(User(payload));
      }
    },
    UPDATE_USER(state, payload) {
      if (payload) {
        const { userId, data } = payload;

        if (!userId || !data) {
          consoleError('UserId or Data is missing!');
          return;
        }

        const user = state.users.find(u => u.id === userId);

        if (user) {
          Object.entries(data).forEach(item => {
            Vue.set(user, item[0], item[1]);
          });
        }
      }
    },
    REMOVE_USER(state, payload) {
      const findIndex = state.users.findIndex(user => user.id === payload);
      if (findIndex !== -1) {
        Vue.delete(state.users, findIndex);
      }
    },
    CLEAR_USERS(state) {
      state.users = [];
    },
    SET_AUDIO_MUTE_STATE(state, { userId, muteState }) {
      const user = state.users.find(u => u.id === userId);
      if (user) {
        user.audioMuted = muteState;
      }
    },
    SET_VIDEO_MUTE_STATE(state, { userId, muteState }) {
      const user = state.users.find(u => u.id === userId);
      if (user) {
        user.videoMuted = muteState;
      }
    },
    SET_WAITING_FOR_THE_HOST(state, payload) {
      state.waitingForTheHost = payload;
    },
    SET_CLEAR_QUERY_PARAMS(state, payload) {
      state.clearQueryParams = payload;
    },
    SET_SPEAKER_MUTE_STATE(state, payload) {
      state.speakerMuted = payload;
    },
    SHOW_CONFERENCE_PASSWORD_MODAL(state, payload) {
      state.showConferencePasswordModal = payload;
    },
    SET_CURRENT_RIGHT_MENU_TAB(state, payload) {
      state.activeRightMenuTab = payload;
    },
    READ_MESSAGES(state) {
      state.messages = state.messages.map(m => {
        m.unread = false;
        return m;
      });
    },
    TOGGLE_CHAT_SCREEN(state, payload) {
      if (payload?.close) {
        state.showChatScreen = false;
        return;
      }

      if (state.activeRightMenuTab === 'user-list' && state.showChatScreen) {
        state.showChatScreen = payload ? payload.state : true;
      } else {
        state.showChatScreen = payload ? payload.state : !state.showChatScreen;
      }

      state.activeRightMenuTab = 'chat';

      // if chat screen is open
      // set all messages as read
      if (state.showChatScreen && !state.isChatScrolled.conference) {
        state.messages = state.messages.map(m => {
          m.unread = false;
          return m;
        });
      }
    },
    TOGGLE_RIGHT_MENU(state, payload = {}) {
      const { open, tab } = payload;
      state.showChatScreen = open ?? !state.showChatScreen;
      state.activeRightMenuTab = tab;
    },
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    SET_PRIVATE_MESSAGE_TO(state, payload) {
      state.sendPrivateMessageTo = payload;
    },
    SET_LOCK_STATE(state, payload) {
      state.locked = payload;
    },
    SET_E2EE_STATE(state, payload) {
      state.e2eeEncrypt = payload;
    },
    SET_ALL_AUDIO_MUTED(state, payload) {
      state.allAudioMuted = payload;
    },
    SET_ALL_VIDEO_MUTED(state, payload) {
      state.allVideoMuted = payload;
    },
    // global olarak yapılacak
    TOGGLE_LOGOUT_CONFIRM_MODAL(state, payload) {
      state.showLogoutConferenceModal = payload;
    },
    SET_CONFERENCE_RECORDS(state, payload) {
      state.remoteRecords = payload;
    },
    ADD_USER_TO_SCREEN_SHARE_LIST(state, userId) {
      const userIndex = state.screenSharingUserList.findIndex(u => u.id === userId);
      if (userIndex === -1) {
        state.screenSharingUserList.push({ userId, startDate: new Date() });
      }
    },
    REMOVE_USER_TO_SCREEN_SHARE_LIST(state, userId) {
      const userIndex = state.screenSharingUserList.findIndex(u => u.userId === userId);
      if (userIndex > -1) {
        Vue.delete(state.screenSharingUserList, userIndex);
      }
    },
    SET_CONFERENCE_JOINED(state, payload) {
      state.conferenceJoined = payload;
    },
    // eslint-disable-next-line
    SET_INITIAL_STATE(state) {
      // eslint-disable-next-line
      state = initialState();
    },
    CHANGE_LAYOUT_TYPE(state, payload) {
      state.layoutType = payload;
    },
    SET_E2EE_MASTER_PASSWORD(state, payload) {
      state.e2eeMasterPassword = payload;
    },
    SET_SHARED_VIDEO(state, payload) {
      if (payload.status === 'stop') {
        // Clear state on stop
        Vue.set(state, 'sharedVideo', {
          value: null,
          status: 'stop', // "start" | "pause" | "playing" | "stop"
          from: null, // userId
          time: '0',
          volume: '100',
          muted: 'true',
          provider: 'youtube',
        });
      } else {
        Vue.set(state, 'sharedVideo', payload);
      }
    },
    SET_PAGE_SIZE(state, payload) {
      state.pageSize = payload;
    },
    SET_PREFERRED_VIDEO_QUALITY(state, payload) {
      state.preferredVideoQuality = payload;
    },
    SET_CONFERENCE_MAX_USER_REACHED(state, payload) {
      state.conferenceMaxUserReached = payload;
    },
    SET_MANAGE_USER(state, payload) {
      Vue.set(state, 'user', {
        ...payload,
      });
    },
    SET_IS_CHAT_SCROLLED(state, payload) {
      const { type, value } = payload;
      state.isChatScrolled[type] = value;
    },
    SET_SERVER_TIME(state, payload) {
      state.serverTime = payload;
    },
  },
  actions: {
    sendNotificationToRoomOwner(_, payload) {
      return ManageService.sendNotificationToRoomOwner(payload);
    },
    async setArFileList({ state, getters, dispatch }) {
      if (!getters.getLocalUser.isModerator) {
        return;
      }

      let resultContainer = {};
      const myPayload = {
        isRoomRecord: false,
        getOwnerOnly: false,
        extensions: ['.usdz'],
      };
      let driveFiles = await XperdriveLib.GetFiles(myPayload);

      const arFileList = driveFiles.data.result.map(item => {
        return {
          id: item.fileId,
          name: item.name,
          fileKey: item.fileKey,
        };
      });

      /* TODO: token gitmeyecek daha sonra kaldırılacak */
      resultContainer = {
        apiUrl: window.XPER_CONFIG.drive,
        token: window.localStorage.getItem('keycloak-token'),
        fileList: [...arFileList, ...(state.roomConfig?.arFileList?.fileList || [])].filter(e => !!e),
      };

      dispatch('setRoomConfig', {
        ...state.roomConfig,
        [RoomConfigEnums.AR_FILE_LIST]: resultContainer,
      });
    },
    setPrivateMessageTo({ state, commit, dispatch }, payload) {
      if (state.roomConfig[RoomConfigEnums.ALLOW_PRIVATE_CHAT]) {
        commit('SET_PRIVATE_MESSAGE_TO', payload);
      } else {
        dispatch('Notification/showToastNotification', { body: 'privateMessageNotAllowed', config: { position: TOAST_POSITION.RIGHT_BOTTOM } }, { root: true });
      }
    },
    setMasterKeyCustomer({ commit, dispatch, getters }, { e2eePassword, e2eeEnabled = true }) {
      /* passwerks_e2ee */

      return new Promise((resolve, reject) => {
        if (e2eeEnabled) {
          try {
            // init passwerks init sen ee password and callback message fcn
            XperMeetLib.passwerks.init(e2eePassword, (msg, color) => {
              if (color === 'success') {
                commit('SET_E2EE_MASTER_PASSWORD', e2eePassword); // set state
                dispatch('setSpeakerMute', false);
                // XperMeetLib.conference.setRoomProperty(RoomConfigEnums.E2EE_ENABLED, true);
                XperMeetLib.conference.localUser.toggleE2ee(true);
                XperMeetLib.conference.room.toggleE2EE(true);
                // TODO: https://bugs.chromium.org/p/chromium/issues/detail?id=1103280
                // XperMeetLib.conference.room._restartMediaSessions();
                dispatch('Notification/showToastNotification', { body: msg, config: { type: color } }, { root: true });
                resolve({ msg, color });
              } else {
                reject({ msg, color });
              }
            });
          } catch (e) {
            consoleError('XperMeetLib.passwerks.init ERROR: ', e);
            reject();
          }
        } else {
          // if not set e2eemaster password toggle e2ee false
          commit('SET_E2EE_MASTER_PASSWORD', ''); // set state
          // if more than two users are logged in we shouldn't set global property to false
          if (getters.getLocalUser.authenticated) {
            // XperMeetLib.conference.setRoomProperty(RoomConfigEnums.E2EE_ENABLED, false);
            XperMeetLib.conference.localUser.toggleE2ee(false);
            XperMeetLib.conference.room.toggleE2EE(false);

            dispatch('Notification/showToastNotification', { body: 'theSystemWillRestartShortly', config: { type: TOAST_TYPE.WARNING } }, { root: true });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
            // TODO: https://bugs.chromium.org/p/chromium/issues/detail?id=1103280
            // XperMeetLib.conference.room._restartMediaSessions();
          }
          resolve();
        }
      });
    },
    getConferenceRecords({ commit }, callBack) {
      XperdriveLib.GetFiles({ isRoomRecord: true })
        .then(response => {
          commit('SET_CONFERENCE_RECORDS', response.data.result);
          if (callBack && typeof callBack === 'function') {
            callBack();
          }
        })
        .catch(err => {
          if (callBack && typeof callBack === 'function') {
            callBack(err);
          }
        });
    },
    startRecording({ commit, dispatch }, isLocal = false) {
      if (isLocal) {
        navigator.mediaDevices
          .getDisplayMedia({
            preferCurrentTab: true,
            audio: true,
          })
          .then(track => {
            XperMeetLib.localRecordingManager.start(track).then(isStarted => {
              if (isStarted) {
                commit('SET_RECORDING_STATUS', GLOBAL_ENUM.RECORDING_STATUS.LOCAL_RECORD);
              }
            });
          });
      } else {
        commit('SET_RECORDING_STATUS', GLOBAL_ENUM.RECORDING_STATUS.PENDING);
        XperMeetLib.remoteRecordingManager.startRecording().catch(error => {
          consoleError('Start Recording Error:', error);
          commit('SET_RECORDING_STATUS', GLOBAL_ENUM.RECORDING_STATUS.REMOTE_RECORD_ERROR);
          dispatch('Notification/showToastNotification', 'startRecordingErrorNotification', { root: true });
        });
      }
    },
    stopRecording({ commit, getters, dispatch }) {
      if (getters.getLocalUser.localRecording) {
        XperMeetLib.localRecordingManager.stop();
        commit('SET_RECORDING_STATUS', GLOBAL_ENUM.RECORDING_STATUS.READY_TO_DOWNLOAD);
      } else {
        commit('SET_RECORDING_STATUS', GLOBAL_ENUM.RECORDING_STATUS.PENDING);
        XperMeetLib.remoteRecordingManager
          .stopRecording()
          .then(() => {
            dispatch(
              'Notification/sendRoomNotification',
              { text: 'stoppedRecording', userId: getters.getLocalUser.id, type: ROOM_NOTIFICATION_TYPES.STOP_RECORD },
              { root: true },
            );
          })
          .catch(err => {
            commit('SET_RECORDING_STATUS', GLOBAL_ENUM.RECORDING_STATUS.STOP);
            consoleError('Stop Recording Error:', err);
            dispatch('Notification/showToastNotification', { body: 'somethingWentWrong', config: { type: TOAST_TYPE.ERROR } }, { root: true });
          });
      }
    },
    async sendMeetingRequest({ dispatch }, payload) {
      try {
        await XperMeetLib.conference.localUser.sendMeetingRequest(payload);
        dispatch('Notification/showToastNotification', 'meetingRequestSended', { root: true });
        return true;
      } catch (error) {
        dispatch('Notification/showToastNotification', { body: 'meetingRequestError', config: { type: TOAST_TYPE.ERROR } }, { root: true });
        return false;
      }
    },
    async uploadUserAvatar({ }, payload) {
      try {
        const res = await ManageService.uploadUserAvatar(payload);
        if ((res?.status === 201 || res?.status === 200) && res?.data) {
          return res.data.avatar_url;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async uploadGuestAvatar({ dispatch }, payload) {
      try {
        const { promise } = await XperdriveLib.ImageUploadAnonymous({ width: 500 }, payload);
        const res = await promise;
        if (res?.status === 200 && res?.data?.httpStatusCode === 200) {
          return res.data.result;
        } else {
          dispatch('Notification/showToastNotification', { body: 'avatarUploadError', config: { type: TOAST_TYPE.ERROR } }, { root: true });
          return false;
        }
      } catch (error) {
        dispatch('Notification/showToastNotification', { body: 'avatarUploadError', config: { type: TOAST_TYPE.ERROR } }, { root: true });
        return false;
      }
    },
    async imageUploadAnonymous({ dispatch }, { params, errorText, payload }) {
      try {
        const { promise } = await XperdriveLib.ImageUploadAnonymous(params, payload);
        const res = await promise;
        if (res?.status === 200 && res?.data?.httpStatusCode === 200) {
          return res.data.result;
        } else {
          dispatch('Notification/showToastNotification', { body: errorText, config: { type: TOAST_TYPE.ERROR } }, { root: true });
          return false;
        }
      } catch (error) {
        dispatch('Notification/showToastNotification', { body: errorText, config: { type: TOAST_TYPE.ERROR } }, { root: true });
        return false;
      }
    },
    async deleteUserAvatar({ dispatch }) {
      try {
        const res = await ManageService.deleteUserAvatar();
        if (res?.status !== 204) {
          dispatch('Notification/showToastNotification', { body: 'avatarDeleteError', config: { type: TOAST_TYPE.ERROR } }, { root: true });
        }
        return true;
      } catch (error) {
        dispatch('Notification/showToastNotification', { body: 'avatarDeleteError', config: { type: TOAST_TYPE.ERROR } }, { root: true });
        return false;
      }
    },
    setDisplayName({ commit, getters }, displayName) {
      const localUser = getters.getLocalUser;
      if (displayName) {
        XperMeetLib.conference.localUser.setDisplayName(displayName);
        storage.setItem('displayName', displayName);
        commit('UPDATE_USER', { userId: localUser.id, data: { displayName } });
        commit('TOGGLE_PROFILE_MODAL', false);
      }
    },
    setAvatar({ commit, getters }, avatar) {
      const localUser = getters.getLocalUser;
      commit('UPDATE_USER', { userId: localUser.id, data: { avatar } });
      XperMeetLib.conference.localUser.setAvatar(avatar); // lib icin commit yapilmali build
      storage.setItem('avatar', avatar);
    },
    sendMessage({ commit, getters, state }, payload) {
      const { message, userId = null } = payload;

      if (userId) {
        const to = state.users.find(u => u.id === userId);
        const user = getters.getLocalUser;
        if (!to || !user) return;

        XperMeetLib.conference.sendMessage(message, userId);
        commit('ADD_MESSAGE', {
          userId: user.id,
          id: state.messages.length + 1,
          date: new Date(),
          body: message,
          sender: user.displayName,
          private: true,
          to: to.displayName,
        });
      } else {
        XperMeetLib.conference.sendMessage(message);
      }
    },
    setRoomConfig({ commit }, payload) {
      XperMeetLib.conference.roomConfig.updateDataSource(payload);
      commit('SET_ROOM_CONFIG', payload);
    },
    // eslint-disable-next-line
    async initXperMeet({ commit, dispatch, state, getters, rootGetters, rootState }, roomConfig) {
      const { roomName, password, startMutedPolicyConfig, token, startWithAudioMuted, startWithVideoMuted, joinAsGuest } = roomConfig;

      if (!roomName) {
        consoleError('RoomName is required');
        return;
      }

      if (storage.getItem('startWithSpeakerMuted') === true) {
        dispatch('setSpeakerMute', true);
      }

      if (roomConfig.isEvent && token && parseJwt(token)?.room === roomName) {
        commit('SET_IS_EVENT', true);
      } else {
        localStorage.removeItem('event-token');
      }

      consoleLog('Connection Initializing:', roomName);

      const deviceDeviceIds = getLocalSelectedDevices();
      commit('SET_XPER_MEET_INITIALIZE', false);
      commit('SET_CONFERENCE_JOINED', false);

      commit('SET_INITIAL_STATE');
      commit('Conference/SET_INITIAL_STATE', null, { root: true });
      commit('Lobby/SET_INITIAL_STATE', null, { root: true });
      commit('SET_SYSTEM_ERROR', null, { root: true });

      XperMeetLib.initWithConnection({
        config: getServerConfig(),
        roomName,
        password,
        deviceDeviceIds,
        startMutedPolicyConfig,
        token,
        startWithAudioMuted,
        startWithVideoMuted,
        joinAsGuest,
      })
        .then(response => {
          commit('SET_XPER_MEET_INITIALIZE', true);
          commit('SET_ROOM_NAME', roomName);

          XperMeetLib.connectionManager.on('ConnectionFailed', ([error, msg]) => {
            consoleError('ConnectionFailed: ', { error, msg });

            if (msg === 'not authorized to create room') {
              XperMeetLib.conference.emit('ConferenceFailed', ERROR_WITH_CODES.AUTHENTICATION_REQUIRED);
              commit('SET_WAITING_FOR_THE_HOST', true);
            } else if (error === ERROR_WITH_CODES.CONNECTION_PASSWORD_REQUIRED) {
              if (window.XPER_CONFIG?.serverConfig?.keycloak?.hosts?.anonymousdomain) {
                localStorage.removeItem('event-token');
                dispatch('Notification/showToastNotification', 'roomIsNotActiveError', { root: true });
              } else {
                XperMeetLib.keycloakManager.redirectLoginPage();
              }
            }
          });

          /* onroom property change onRoompropertyChange */
          XperMeetLib.conference.roomConfig.on('configParameterChanged', ({ key, value }) => {
            consoleLog(`Changed room config: ${key} - ${value}`);

            const { START_MUTED_POLICY, START_VIDEO_MUTED_POLICY, ALLOW_ADMIN_START_VIDEO } = RoomConfigEnums;
            const newConfigObject = Object.assign({}, state.roomConfig, {
              [key]: value,
            });

            // if moderator changeroom configuration
            // clients must change instantly.
            // below code set room config on the fly...
            if (START_MUTED_POLICY === key || START_VIDEO_MUTED_POLICY === key || ALLOW_ADMIN_START_VIDEO === key) {
              // Will be remove in future, only required for jitsi mobile app.
              XperMeetLib.conference.setStartMutedPolicy({
                startMutedPolicy: newConfigObject.startMutedPolicy,
                startVideoMutedPolicy: newConfigObject.startVideoMutedPolicy,
              });
            }

            if (key === START_MUTED_POLICY) {
              XperMeetLib.conference.localUser.onAudioStartMutedPolicyChange(value);
            }

            if (key === START_VIDEO_MUTED_POLICY) {
              XperMeetLib.conference.localUser.onVideoStartMutedPolicyChange(value);
            }

            if (key === RoomConfigEnums.AR_FILE_LIST) {
              XperMeetLib.ARModule.sendArModelList(value);
            }

            if (key === RoomConfigEnums.FULL_SCREEN_USER_ID) {
              if (getters.getUsers.length === 1) {
                commit('SET_FULL_SCREEN', { userId: getters.getUsers[0].id, fullScreen: true });
              } else {
                commit('SET_FULL_SCREEN', { userId: value, fullScreen: !!value });
              }
            }

            if (key === RoomConfigEnums.PACKAGE_CONFIG) {
              const role = getters.getLocalUser.isModerator ? ROLE_ADMIN : ROLE_PARTICIPANT;
              setPackagePermissions(role, value);
            }

            if (key === RoomConfigEnums.FOLLOW_MODERATOR_FULL_SCREEN) {
              if (getters.getFullScreenUserId) {
                commit('SET_FULL_SCREEN', { userId: getters.getFullScreenUserId, fullScreen: false });
              }
            }

            commit('SET_ROOM_CONFIG', newConfigObject);
          });

          /* Local User Events */
          XperMeetLib.conference.on('LockStateChanged', locked => {
            commit('SET_LOCK_STATE', locked);
          });

          /* status if user disconnected status is going to be interrupted
           * set this as a flag in the user object for showing the current
           * connection status of the user
           */
          XperMeetLib.conference.on('ParticipantConnStatusChanged', ({ userId, status }) => {
            consoleLog('ParticipantConnStatusChanged: ', { userId, status });
            let data = {};
            switch (status) {
              case USER_CONNECTION_STATUS.INTERRUPTED:
                status = { interrupted: true, inactive: false };
                break;
              case USER_CONNECTION_STATUS.ACTIVE:
                status = { interrupted: false, inactive: false };
                break;
              case USER_CONNECTION_STATUS.INACTIVE:
                status = { inactive: true, interrupted: false };
                break;
              default:
                consoleWarning('Unknowm user status:', { userId, status });
            }
            commit('UPDATE_USER', { userId, data });
          });

          XperMeetLib.conference.on('SurveyShared', () => {
            dispatch('Survey/getSharedSurveys', 'room', { root: true });
          });

          XperMeetLib.conference.on('LocalUserJoined', async localUser => {
            if (localUser.isJibri) {
              commit('SET_JIBRI', true);
              return;
            }
            consoleLog('ADD_LOCAL_USER', localUser); //eslint-disable-line

            const config = window.customerConfig;
            const allowChangeDisplayName = config?.system?.users?.allowChangeDisplayName;
            let displayName = storage.getItem('displayName') || localUser.displayName;
            const token = XperMeetLib?.keycloakManager?.keycloakInstance?.token;

            if (!allowChangeDisplayName && token) {
              try {
                const userInfo = await XperMeetLib.keycloakManager.keycloakInstance.loadUserInfo();
                const userNameKey = config?.system?.users?.getUserNameFromTokenKey || 'name';
                displayName = userNameKey ? userInfo[userNameKey] : userInfo.name;
              } catch (error) {
                consoleLog(error);
              }
            }
            const avatar = storage.getItem('avatar') || '';

            commit('ADD_USER', {
              id: localUser.id,
              type: 'local',
              displayName,
              handsUp: localUser.handsUp === 'true',
              avatar,
            });

            changeRoles([ROLE_PARTICIPANT]);
            dispatch('setAvatar', avatar);
            if (!displayName) {
              commit('TOGGLE_PROFILE_MODAL', true);
            } else {
              dispatch('setDisplayName', displayName);
            }
          });
          XperMeetLib.conference.localUser.on('LocalTrackAdded', ({ userId, track }) => {
            consoleLog('LocalTrackAdded', track.getType());
            const container = document.getElementById(`local-${track.getType()}-${userId}`);
            commit('ADD_USER_TRACK', { userId, track });
            dispatch('attachTrackToElement', { container, userType: 'local', userId, track });
          });

          XperMeetLib.conference.on('LocalTrackRemoved', ({ userId, track }) => {
            consoleLog('LocalTrackRemoved');
            commit('REMOVE_USER_TRACK', { userId, track });
          });

          /* Remote User Events */

          XperMeetLib.conference.on('RemoteTrackAdded', ({ participantId, track }) => {
            const container = document.getElementById(`remote-${track.getType()}-${participantId}`);
            commit('ADD_USER_TRACK', { userId: participantId, track });
            dispatch('attachTrackToElement', { container, userType: 'remote', userId: participantId, track });
            dispatch('setSpeakerMute', state.speakerMuted);
          });

          XperMeetLib.conference.on('RemoteTrackRemoved', ({ userId, track }) => {
            consoleLog('RemoteTrackRemoved', userId);
            commit('REMOVE_USER_TRACK', { userId, track });
          });

          XperMeetLib.conference.on('UserPropertyChange', async ({ userId, property, value }) => {
            switch (property) {
              case 'authenticated':
                value = value === 'true';
                break;
              case 'handsUp':
                if (getters.getLocalUser.id !== userId) {
                  if (value === 'true') {
                    dispatch(
                      'Notification/showRoomNotification',
                      { text: 'handRaised', userId: userId, type: ROOM_NOTIFICATION_TYPES.HANDS_UP },
                      { root: true },
                    );
                  } else {
                    dispatch('Notification/removeRoomNotificationByType', { type: ROOM_NOTIFICATION_TYPES.HANDS_UP, userId }, { root: true });
                  }
                }
                value = value === 'true';
                break;
              case 'hasCamera':
              case 'hasMicrophone':
              case 'ARTrack':
              case 'localUserE2ee':
              case 'speakerMuted':
              case 'isMobile': {
                value = value === 'true';
                break;
              }
              case 'avatar':
                break;
              case 'localRecording':
                // TODO: yama olarak yaptık devamlı gostermesin recordd stop notificationu diye
                // false olduktan sonra lib tarafında bir de null yapıyoruz ki norificationa dusmesin
                if (value === 'true') {
                  if (getters.getLocalUser.id !== userId) {
                    dispatch('Notification/showRoomNotification', { text: 'recording', type: ROOM_NOTIFICATION_TYPES.RECORD, userId }, { root: true });
                  }
                  dispatch('Notification/playSound', NOTIFICATION_SOUND_TYPES.RECORDING, { root: true });
                } else if (value === 'false') {
                  if (getters.getLocalUser.id !== userId) {
                    dispatch(
                      'Notification/showRoomNotification',
                      { text: 'stoppedRecording', type: ROOM_NOTIFICATION_TYPES.STOP_RECORD, userId },
                      { root: true },
                    );
                  }
                  dispatch('Notification/playSound', NOTIFICATION_SOUND_TYPES.RECORDING, { root: true });

                  commit('SET_RECORDING_STATUS', GLOBAL_ENUM.RECORDING_STATUS.READY_TO_DOWNLOAD);
                }
                value = value === 'true';
                break;
              case 'start_time':
                const onRecordStartTime = XperMeetLib.remoteRecordingManager.getRecordStartTime();
                commit('SET_REMOTE_RECORD_START_TIME', parseInt(onRecordStartTime));
                break;
              default:
                break;
            }
            commit('UPDATE_USER', { userId, data: { [property]: value } });
          });

          XperMeetLib.remoteRecordingManager.on('RemoteRecordStarted', showIsRecording => {
            const { REMOTE_RECORD, STOP } = GLOBAL_ENUM.RECORDING_STATUS;
            const status = showIsRecording ? REMOTE_RECORD : STOP;
            if (state.recordingStatus !== status) {
              commit('SET_RECORDING_STATUS', status);
              const onRecordStartTime = XperMeetLib.remoteRecordingManager.getRecordStartTime();
              commit('SET_REMOTE_RECORD_START_TIME', parseInt(onRecordStartTime));
            }
          });

          XperMeetLib.remoteRecordingManager.on('RemoteRecordStopped', ({ errorCode }) => {
            const { STOP } = GLOBAL_ENUM.RECORDING_STATUS;

            if (errorCode) {
              if (errorCode === ERROR_WITH_CODES.NOT_ENOUGHT_SPACE) {
                dispatch('Notification/showToastNotification', { body: 'notEnoughtSpaceError', config: { type: TOAST_TYPE.ERROR } }, { root: true });
              } else if (errorCode === ERROR_WITH_CODES.RECORD_FAILED_TO_START) {
                dispatch('Notification/showToastNotification', { body: 'startRecordingErrorNotification', config: { type: TOAST_TYPE.ERROR } }, { root: true });
              }
            }
            commit('SET_RECORDING_STATUS', STOP);
          });
          XperMeetLib.conference.on('onChatNotificationMessageReceived', payload => {
            const { message, type, time, sender, data } = payload;
            dispatch('Notification/onNotificationReceived', { type, data }, { root: true });
            //TODO this should be moved into notification store module and then it will be removed
            if (type === NOTIFICATION_TYPES.CHAT) {
              dispatch('onChatNotificationReceived', { message, time, sender, data });
            }
          });

          XperMeetLib.conference.on('RemoteUserJoined', remoteUser => {
            consoleLog('ADD_REMOTE_USER', remoteUser.id); //eslint-disable-line

            commit('ADD_USER', {
              id: remoteUser.id,
              type: 'remote',
              displayName: remoteUser.displayName,
              role: remoteUser.role,
              handsUp: remoteUser.handsUp === 'true',
            });
            dispatch('Notification/playSound', NOTIFICATION_SOUND_TYPES.USER_JOINED, { root: true });
            const payload = {
              message: CHAT_NOTIFICATION_TYPES.USER_JOINED_CONFERENCE,
              sender: remoteUser.id,
            };
            dispatch('onChatNotificationReceived', payload);
          });

          XperMeetLib.conference.on('RemoteUserLeft', remoteUserId => {
            consoleLog('RemoteUserLeft', remoteUserId); //eslint-disable-line
            if (remoteUserId === state.dominantUserId) {
              commit('SET_DOMINANT_USER_ID', null);
            }
            const payload = {
              message: CHAT_NOTIFICATION_TYPES.USER_LEFT_CONFERENCE,
              sender: remoteUserId,
            };
            const userNotifications = rootState.Notification.roomNotifications.filter(
              notification => notification.userId === remoteUserId && notification.type !== ROOM_NOTIFICATION_TYPES.FILE_SHARE,
            );
            if (userNotifications.length) {
              userNotifications.forEach(notification => {
                commit('Notification/REMOVE_ROOM_NOTIFICATION', notification.id, { root: true });
              });
            }
            dispatch('onChatNotificationReceived', payload);
            commit('REMOVE_USER', remoteUserId);
          });

          /* Any User Events */

          XperMeetLib.conference.on('UserDisplayNameChanged', ({ userId, displayName }) => {
            const participant = getters.getUserById(userId);
            if (participant.displayName !== displayName) {
              dispatch(
                'Notification/showToastNotification',
                { body: i18n.t('userDisplayNameChanged', { old: participant.displayName, new: displayName }), translate: false },
                { root: true },
              );
              commit('UPDATE_USER', { userId, data: { displayName } });
            }
          });

          XperMeetLib.conference.on('UserRoleChanged', async ({ userId, role }) => {
            commit('UPDATE_USER', { userId, data: { role } });

            if (userId === getters.getLocalUser.id) {
              if (role === 'moderator') {
                const packageConfig = await dispatch('setFeatureSettings');
                // When the creator join the room then the room settings will be applied
                if (getters.getUsers.length === 1 && getters.getLocalUser.isModerator) {
                  const startConfig = await dispatch('fetchRoomConfigFromManage', { roomName });
                  dispatch('setPackageConfigToRoom', { packageConfig, startConfig });
                  if (getters.lobbyAutoEnable) {
                    dispatch('Lobby/enableLobby', true, { root: true });
                  }
                }
                changeRoles([ROLE_ADMIN]);
                setPackagePermissions(ROLE_ADMIN, state.roomConfig[RoomConfigEnums.PACKAGE_CONFIG], getters.getUsers.length > 1, packageConfig);
              } else {
                changeRoles([ROLE_PARTICIPANT]);
                setPackagePermissions(ROLE_PARTICIPANT, state.roomConfig[RoomConfigEnums.PACKAGE_CONFIG]);
              }
            }
          });

          XperMeetLib.conference.on('UserAudioMuteChanged', ({ userId, muteState }) => {
            commit('SET_AUDIO_MUTE_STATE', { userId, muteState });
          });

          XperMeetLib.conference.on('UserVideoMuteChanged', ({ userId, muteState }) => {
            commit('SET_VIDEO_MUTE_STATE', { userId, muteState });
          });

          XperMeetLib.conference.on('UserAudioMutedByModeratorChanged', ({ userId, audioMutedByModerator }) => {
            commit('UPDATE_USER', { userId, data: { audioMutedByModerator } });
          });

          XperMeetLib.conference.on('UserVideoMutedByModeratorChanged', ({ userId, videoMutedByModerator }) => {
            commit('UPDATE_USER', { userId, data: { videoMutedByModerator } });
          });

          XperMeetLib.conference.on('UserAudioMuteChangedByModerator', ({ userId, muteState, userCanUnmute, moderatorDisplayName }) => {
            const audioMutedByModerator = state.roomConfig.allowAdminStartVideo && muteState;
            commit('SET_AUDIO_MUTE_STATE', { userId, muteState });
            if (!userCanUnmute) {
              commit('UPDATE_USER', {
                userId,
                data: {
                  audioMutedByModerator,
                  audioMutedBy: moderatorDisplayName,
                },
              });
            }
            const message = i18n.t(muteState ? 'audioMutedByModerator' : 'audioUnMutedByModerator', { moderatorDisplayName });
            dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
          });

          XperMeetLib.conference.on('UserVideoMuteChangedByModerator', ({ userId, muteState, moderatorDisplayName }) => {
            const videoMutedByModerator = state.roomConfig.allowAdminStartVideo && muteState;
            commit('SET_VIDEO_MUTE_STATE', { userId, muteState });
            commit('UPDATE_USER', {
              userId,
              data: {
                videoMutedByModerator,
                videoMutedBy: moderatorDisplayName,
              },
            });
            const message = i18n.t(muteState ? 'videoMutedByModerator' : 'videoUnMutedByModerator', { moderatorDisplayName });
            dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
          });

          // Messages
          XperMeetLib.conference.on('onMessageReceived', data => {
            const [userId, message, timestamp, nickName] = data;
            const localUser = state.users.find(u => u.type === 'local');
            const sender = state.users.find(u => u.id === userId);
            let unread = !state.showChatScreen;
            if (state.showChatScreen) {
              unread = state.isChatScrolled.conference || state.activeRightMenuTab !== 'chat';
            }
            try {
              // file share
              const files = JSON.parse(message);
              files.forEach(file => {
                commit('ADD_FILE', { ...file, user: nickName, date: new Date(), userId });
                commit('ADD_MESSAGE', {
                  file,
                  userId,
                  id: state.messages.length + 1,
                  date: timestamp ? new Date(timestamp) : new Date(),
                  sender: nickName,
                  incoming: localUser.id !== userId,
                  unread,
                });
              });
            } catch (e) {
              // message
              commit('ADD_MESSAGE', {
                userId,
                id: state.messages.length + 1,
                body: message,
                date: timestamp ? new Date(timestamp) : new Date(),
                sender: nickName || sender?.displayName,
                incoming: localUser.id !== userId,
                unread,
              });
            }
          });

          XperMeetLib.conference.on('onPrivateMessageReceived', ([userId, message, timestamp]) => {
            const sender = getters.getUserById(userId);
            const localUser = state.users.find(u => u.type === 'local');

            try {
              // private file share
              const files = JSON.parse(message);
              files.forEach(file => {
                commit('ADD_FILE', { ...file, nickName: sender.displayName, private: true, userId });
              });
            } catch (e) {
              commit('ADD_MESSAGE', {
                userId,
                private: true,
                id: state.messages.length + 1,
                body: message,
                date: timestamp ? new Date(timestamp) : new Date(),
                sender: sender.displayName,
                incoming: localUser.id !== userId,
                unread: !state.showChatScreen ? !state.showChatScreen : !!state.isChatScrolled.conference && state.activeRightMenuTab === 'chat',
              });
            }
          });

          // eslint-disable-next-line
          XperMeetLib.conference.on('onEndpointMessageReceived', data => {
            // const [userId, message, timestamp, nickName] = data;
            // consoleLog('onEndpointMessageReceived VUE', [userId, message, timestamp, nickName]);
          });

          // eslint-disable-next-line
          XperMeetLib.conference.on('onDominantUserChanged', ([userId]) => {
            if (getters.getLocalUser.id !== userId) {
              commit('SET_DOMINANT_USER_ID', userId);
            }
            commit('SET_CURRENT_SPEAKING_USER_ID', userId);
          });

          // fetchDevices triggered twice because of mobile web doesn't seem devices on first fetch
          dispatch('Devices/fetchDevices', {}, { root: true }).then(() => {
            dispatch('Devices/fetchDevices', {}, { root: true }).then(() => {
              dispatch('Devices/changeSpeakerById', deviceDeviceIds.audioOutput, { root: true });
            });
          });

          /* Room / Conference Events */

          XperMeetLib.conference.on('RoomEntryBlocked', () => {
            if (rootState.Notification.toastNotifications.some(t => t.body === 'roomEntryBlocked.text')) {
              return;
            }
            const licenseType = state.user?.license?.BIZBIZE?.license_type;
            let upgradeUrl = `${window.XPER_CONFIG.manageUrl}/upgrade`;
            if (licenseType === 'FREE') {
              upgradeUrl = i18n.locale === 'tr' ? `${process.env.VUE_APP_BIZBIZE_WEB_URL}/fiyatlandirma` : `${process.env.VUE_APP_BIZBIZE_WEB_URL}/en/pricing`;
            }
            const config = {
              timeout: 0,
              buttons: [
                { text: 'close', close: true },
                {
                  text: 'roomEntryBlocked.upgrade',
                  action: () => {
                    createATagAndClick(upgradeUrl, '_blank');
                  },
                  bold: true,
                },
              ],
            };
            dispatch('Notification/showToastNotification', { body: 'roomEntryBlocked.text', config }, { root: true });
          });

          XperMeetLib.conference.on('ConferenceFailed', error => {
            consoleLog(error);

            if (error === ERROR_WITH_CODES.PASSWORD_REQUIRED) {
              commit('SHOW_CONFERENCE_PASSWORD_MODAL', true);
              commit('SET_WAITING_FOR_THE_HOST', false);
            }

            if (error === ERROR_WITH_CODES.AUTHENTICATION_REQUIRED) {
              commit('SET_WAITING_FOR_THE_HOST', true);
            }

            if (error === ERROR_WITH_CODES.MEMBERS_ONLY) {
              dispatch('joinWithLobby');
            }
            if (error === ERROR_WITH_CODES.ROOM_MAX_USERS_ERROR) {
              commit('SET_CONFERENCE_MAX_USER_REACHED', true);
            }

            if (error === ERROR_WITH_CODES.CONFERENCE_ACCESS_DENIED) {
              router.push({ name: 'rejected' });
            }
          });

          XperMeetLib.conference.on('MeetingIdSet', ({ meetingId }) => {
            commit('SET_MEETING_ID', meetingId);
            if (Vue.prototype.$customerConfig?.system?.modules?.survey) {
              dispatch('Survey/getSharedSurveys', 'room', { root: true });
            }
          });

          XperMeetLib.conference.on('ConferenceTimestampChanged', timestamp => {
            commit('SET_CONFERENCE_CREATED_TIMESTAMP', timestamp);
          });

          XperMeetLib.conference.on('ConferenceJoined', async () => {
            commit('SET_WAITING_FOR_THE_HOST', false);
            commit('SET_CLEAR_QUERY_PARAMS', true);
            commit('SET_CONFERENCE_JOINED', true);
            commit('Lobby/SET_SHOW_LOBBY_PRE_MEETING', false, { root: true });
            commit('Lobby/SET_JOIN_RESPONSE_WAITING', false, { root: true });

            // init room config
            try {
              const roomConfig = await XperMeetLib.conference.fetchRoomConfig();
              commit('SET_ROOM_CONFIG', { ...roomConfig });

              // Set if fullscreen id is set
              if (state.roomConfig[RoomConfigEnums.FULL_SCREEN_USER_ID] && getters.isFollowModeratorFullScreen) {
                commit('SET_FULL_SCREEN', { userId: state.roomConfig[RoomConfigEnums.FULL_SCREEN_USER_ID], fullScreen: true });
              }

              const role = getters.getLocalUser.isModerator ? ROLE_ADMIN : ROLE_PARTICIPANT;
              setPackagePermissions(role, roomConfig[RoomConfigEnums.PACKAGE_CONFIG]);
            } catch (e) {
              consoleError('Room Config Fetch Error. Somethings will be wrong');
            }

            // Webinar handle events
            dispatch('Webinar/handleEvents', { roomName }, { root: true });

            // Stats handle events
            dispatch('Stats/handleEvents', { roomName }, { root: true });

            const hasMicrophone = rootGetters['Devices/hasMicrophone'] && rootGetters['Devices/microphoneAllowed'];
            const hasCamera = rootGetters['Devices/hasCamera'] && rootGetters['Devices/cameraAllowed'];

            XperMeetLib.conference.localUser.setLocalParticipantProperty('hasMicrophone', hasMicrophone.toString());
            XperMeetLib.conference.localUser.setLocalParticipantProperty('hasCamera', hasCamera.toString());
            XperMeetLib.conference.localUser.setLocalParticipantProperty('privateRoomName', getters.getPrivateRoomName);

            commit('UPDATE_USER', { userId: getters.getLocalUser.id, data: { hasMicrophone: hasMicrophone, hasCamera: hasCamera } });
          });

          XperMeetLib.conference.on('ScreenShareStarted', ({ userId }) => {
            commit('UPDATE_USER', { userId, data: { screenSharing: true } });
            commit('ADD_USER_TO_SCREEN_SHARE_LIST', userId);
          });

          XperMeetLib.conference.on('StopParticipantScreenShare', () => {
            dispatch('stopScreenShare');
          });

          XperMeetLib.conference.on('ScreenShareStopped', ({ userId }) => {
            commit('UPDATE_USER', { userId, data: { screenSharing: false } });
            commit('REMOVE_USER_TO_SCREEN_SHARE_LIST', userId);
          });

          XperMeetLib.conference.localUser.on('LocalTrackError', ({ devices, message }) => {
            const msg = i18n.t(message, { devices: devices.join(', ') });
            dispatch('Notification/showToastNotification', { body: msg, translate: false }, { root: true });
          });

          XperMeetLib.conference.on('UserKicked', ({ actorParticipantId, kickedParticipantId, reason }) => {
            if (reason !== 'REDIRECT_TO_WEBINAR') {
              const kickedParticipant = getters.getUserById(kickedParticipantId);
              const actorParticipant = getters.getUserById(actorParticipantId);
              const message = i18n.t('userKicked', { who: kickedParticipant?.displayName, by: actorParticipant?.displayName });
              dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
              commit('REMOVE_USER', kickedParticipantId);
            }
          });

          XperMeetLib.conference.on('Kicked', ({ reason }) => {
            if (reason === 'CLOSE_ROOM') {
              router.push('/ended').then(() => {
                router.go();
              });
            } else if (reason === 'REDIRECT_TO_LOBBY') {
              dispatch('Notification/showToastNotification', 'movedToLobby', { root: true });
              const query = window.location.search ? `${window.location.search}&redirected=true` : '?redirected=true';
              window.location.href = `/${state.roomName}${query}`;
            } else if (reason !== 'REDIRECT_TO_WEBINAR') {
              dispatch('Notification/showToastNotification', 'kicked', { root: true });
              router.push('/kicked').then(() => {
                router.go();
              });
            }
          });

          XperMeetLib.conference.on('SharedVideo', payload => {
            const { userId, value, from, status, provider, time, volume, muted } = payload;
            consoleLog(userId, value, from, status, provider, time, volume, muted);

            // Send command like me when any moderator do force update
            if (userId !== getters.getLocalUser.id && from === getters.getLocalUser.id && status === 'stop') {
              dispatch('sharedVideoUpdate', { videoId: state.sharedVideo.value, status: 'stop', forceUpdate: true });
            }

            commit('SET_SHARED_VIDEO', { value, from, status, provider, time, volume, muted });
          });

          XperdriveLib.on('FileUploadStart', () => {
            XperMeetLib.conference.localUser.setSenderVideoConstraint(180);
          });

          XperdriveLib.on('FileUploadEnd', () => {
            XperMeetLib.conference.localUser.setSenderVideoConstraint(720);
          });

          XperMeetLib.conference.on('RedirectToWebinar', () => {
            // router.push({ name: 'webinar', params: { roomName } });
            window.location = `${window.location.origin}/webinar/${roomName}`;
          });

          commit('SET_SYSTEM_ERROR', null, { root: true });
          if (response === 'WrongUserLogin') {
            commit('SET_SYSTEM_ERROR', UI_ERROR_CODES.USER_CANT_JOIN, { root: true });
          } else if (response === 'GuestLogin' && Vue.prototype.$customerConfig?.system?.redirectAnonymousToLogin) {
            XperMeetLib.keycloakManager.redirectLoginPage();
          } else if (response === 'GetJwtFailed') {
            XperMeetLib.logout({ redirectUri: window.location.href, forceLogout: true });
          }
          /* Lobby */
          dispatch('Lobby/handleLobbyEvents', null, { root: true });

          // Stats handle events
          dispatch('Devices/handleEvents', { roomName }, { root: true });
        })
        .catch(error => {
          switch (error) {
            case ERROR_WITH_CODES.ROOM_IS_NOT_ACTIVE: {
              dispatch('Notification/showToastNotification', 'roomIsNotActiveError', { root: true });
              break;
            }

            default:
              consoleError(error);
          }
        })
        .finally(() => { });
    },
    joinWithLobby({ commit, dispatch, getters }) {
      commit('Lobby/SET_LOBBY_ENABLE_STATE', true, { root: true });
      commit('Lobby/SET_SHOW_LOBBY_PRE_MEETING', true, { root: true });
      commit('SET_WAITING_FOR_THE_HOST', false);
      if (!router.app.$route.query?.fromWebinar) {
        dispatch('Lobby/joinLobby', { name: getters.getLocalUser.displayName }, { root: true });
      }
    },
    async fetchRoomOwnerInfo({ commit }, { roomName }) {
      try {
        const { data } = await ManageService.fetchRoomOwnerInfo(roomName);
        commit('SET_IS_PRIVATE_ROOM', true);
        return {
          ...data,
          roomName,
          fullName: `${data?.first_name} ${data?.last_name}`,
        };
      } catch (err) {
        commit('SET_IS_PRIVATE_ROOM', false);
        consoleLog('User owner not found for room: ', roomName);
        return null;
      }
    },
    async fetchUserInfo({ commit }) {
      try {
        const response = await ManageService.fetchUserInfo();
        if (response?.data) {
          commit('SET_MANAGE_USER', response.data);
        }
        return response?.data || null;
      } catch (err) {
        consoleLog("Couldn't fetch user info");
        return null;
      }
    },
    async setFeatureSettings({ rootGetters, dispatch }) {
      try {
        if (!rootGetters['getIsOnPremiseSetup']) {
          const user = await dispatch('fetchUserInfo');
          if (user) {
            let features = user?.license?.BIZBIZE?.features || null;
            if (features) {
              features.recording = user?.license?.CLOUD_RECORD?.status === 'Active';
              features.livestreaming = user?.license?.WEBINAR?.status === 'Active';
            }
            return features;
          }
          return {};
        } else {
          return ONPREMISE_FEATURES;
        }
      } catch (e) {
        consoleError(e);
        return null;
      }
    },
    setPackageConfigToRoom({ state, dispatch }, { packageConfig, startConfig }) {
      try {
        let roomConfig = JSON.parse(JSON.stringify(state.roomConfig));
        const roomPackageConfig = XperMeetLib.conference.roomConfig.getProperty(RoomConfigEnums.PACKAGE_CONFIG);
        if (!roomPackageConfig || (typeof roomPackageConfig === 'object' && Object.keys(roomPackageConfig)?.length === 0)) {
          roomConfig[RoomConfigEnums.PACKAGE_CONFIG] = packageConfig;
        }
        if (startConfig) {
          roomConfig = { ...roomConfig, ...startConfig };
        }

        dispatch('setRoomConfig', roomConfig);
      } catch (e) {
        consoleError('SetPackageConfigToRoom Failed');
      }
    },
    enableLobby({ getters, dispatch, state }, payload) {
      if (!getters.getLocalUser.isModerator) {
        return;
      }

      dispatch('setRoomConfig', {
        ...state.roomConfig,
        [RoomConfigEnums.LOBBY_ENABLED]: payload,
      });
    },
    async fetchRoomConfigFromManage({ dispatch }, { roomName }) {
      try {
        const response = await ManageService.fetchMeetingSummary(roomName);
        const configJson = response?.data?.config_json;
        if (!configJson) {
          return null;
        }
        const { lobbyAutoEnable } = configJson;
        if (lobbyAutoEnable) {
          dispatch('enableLobby', true);
        }
        return generateRoomConfig(configJson);
      } catch (e) {
        consoleError('fetchRoomConfigFromManage Failed');
        return null;
      }
    },
    redirectKeycloakLoginPage() {
      XperMeetLib.keycloakManager.redirectLoginPage();
    },
    setAudioMute({ getters, rootGetters, dispatch }, muteState) {
      if (!rootGetters['Devices/microphoneAllowed']) {
        const message = i18n.t('mediaPermissionDenied', { devices: 'microphone' });
        dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
        return;
      }

      const localUser = getters.getLocalUser;

      if (localUser.audioMutedByModerator && !localUser.isModerator) {
        const message = i18n.t(!muteState ? 'audioMutedByModerator' : 'audioUnMutedByModerator', { moderatorDisplayName: localUser.audioMutedBy || 'Admin' });
        dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
        return;
      }

      XperMeetLib.conference.localUser.muteAudio(muteState);
    },
    setVideoMute({ getters, rootGetters, dispatch }, muteState) {
      if (!rootGetters['Devices/cameraAllowed']) {
        const message = i18n.t('mediaPermissionDenied', { devices: 'camera' });
        dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
        return;
      }

      const localUser = getters.getLocalUser;

      if (localUser.videoMutedByModerator && !localUser.isModerator) {
        const message = i18n.t(!muteState ? 'videoMutedByModerator' : 'videoUnMutedByModerator', { moderatorDisplayName: localUser.videoMutedBy || 'Admin' });
        dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
        return;
      }
      XperMeetLib.conference.localUser.setLocalParticipantProperty('videoMuted', muteState);
      XperMeetLib.conference.localUser.muteVideo(muteState);
    },
    setRemoteAudioMute({ state, dispatch }, { participantId, muteState }) {
      const { allowAdminStartVideo } = state.roomConfig;
      if (!allowAdminStartVideo && !muteState) {
        dispatch('Notification/showToastNotification', 'audioStartNotAllowed', { root: true });
        return;
      }

      XperMeetLib.conference.muteRemoteAudio(participantId, muteState);
    },
    allowSpeakRequest(context, { participantId }) {
      XperMeetLib.conference.muteRemoteAudio(participantId, false);
      XperMeetLib.conference.handsDown(participantId);
    },
    handsDown(context, { participantId }) {
      XperMeetLib.conference.handsDown(participantId);
    },
    setRemoteVideoMute({ state, dispatch }, { participantId, muteState }) {
      const { allowAdminStartVideo } = state.roomConfig;
      if (!allowAdminStartVideo && !muteState) {
        dispatch('Notification/showToastNotification', 'videoStartNotAllowed', { root: true });
        return;
      }
      XperMeetLib.conference.muteRemoteVideo(participantId, muteState);
    },
    stopParticipantScreenShare(context, participantId) {
      XperMeetLib.conference.stopParticipantScreenShare(participantId);
    },
    setAllRemoteAudioMute({ commit, dispatch }, muteState) {
      dispatch('setRemoteAudioMute', { participantId: 'all', muteState });
      commit('SET_ALL_AUDIO_MUTED', muteState);
    },
    setAllRemoteVideoMute({ commit, dispatch }, muteState) {
      dispatch('setRemoteVideoMute', { participantId: 'all', muteState });
      commit('SET_ALL_VIDEO_MUTED', muteState);
    },
    setSpeakerMute({ commit, getters }, muteState) {
      const elems = document.querySelectorAll('audio');
      [].forEach.call(elems, elem => {
        if (!elem.id.includes(getters.getLocalUser.id)) {
          elem.muted = muteState;
        }
      });
      commit('SET_SPEAKER_MUTE_STATE', muteState);
      XperMeetLib.conference.localUser.setSpeakerMuted(muteState);
    },
    setHandsUp(context, handsUpState) {
      // eslint-disable-line
      consoleLog('setHandsUp', handsUpState);
      XperMeetLib.conference.localUser.handsUp(handsUpState.toString());
    },
    startScreenShare({ getters, dispatch }) {
      XperMeetLib.conference.localUser.startScreenShare(`local-video-${getters.getLocalUser.id}`).catch(errorCode => {
        if (errorCode === ERROR_WITH_CODES.NOT_ALLOWED) {
          dispatch('Notification/showToastNotification', 'screenSharingNotAllowed', { root: true });
        }
      });
    },
    stopScreenShare() {
      XperMeetLib.conference.localUser.stopScreenShare();
    },
    kickParticipant(context, participantId) {
      XperMeetLib.conference.kickParticipant(participantId);
    },
    sendToLobby(context, participantId) {
      XperMeetLib.conference.kickParticipant(participantId, 'REDIRECT_TO_LOBBY');
    },
    redirectLoginPage() {
      XperMeetLib.keycloakManager.redirectLoginPage();
    },
    logout() {
      const config = {};
      if (window.customerConfig?.logoutRedirecUrl) {
        config.redirectUri = `${window.customerConfig?.logoutRedirecUrl}?logout=1`;
      }
      XperMeetLib.logout(config);
    },
    leaveCall() {
      XperMeetLib.leaveCall();
    },
    destroyRoomAndLeave() {
      XperMeetLib.destroyRoomAndLeave();
    },
    lock({ commit, dispatch }, payload) {
      XperMeetLib.conference.lock(payload);
      if (!payload) {
        dispatch('unlock');
      } else {
        commit('SET_CONFERENCE_PASSWORD', payload);
        commit('SET_LOCK_STATE', true);
      }
    },
    unlock({ commit }) {
      XperMeetLib.conference.unlock();
      commit('SET_CONFERENCE_PASSWORD', '');
      commit('SET_LOCK_STATE', false);
    },
    e2eeEncrypt({ commit }, payload) {
      XperMeetLib.conference.e2eeEncrypt(payload);
      commit('SET_E2EE_STATE', true);
    },
    selectParticipant(context, participantId) {
      XperMeetLib.conference.selectParticipant(participantId);
    },
    selectParticipants(context, participantIds) {
      XperMeetLib.conference.selectParticipants(participantIds);
    },
    setLastN(context, lastN) {
      XperMeetLib.conference.setLastN(lastN);
    },
    setReceiverConstraints(context, constraints) {
      XperMeetLib.conference.setReceiverConstraints(constraints);
    },
    attachTrackToElement({ commit }, { container, userType, userId, track }) {
      const property = track.getType() === 'video' ? 'videoMuted' : 'audioMuted';
      commit('UPDATE_USER', { userId, data: { [property]: track.isMuted() } });
      XperMeetLib.conference.attachTrackToElement(container, userType, userId, track);
    },
    async setLayoutConfig({ commit, getters, dispatch }, { layout, layoutType, backgroundImage, sharedProperties }) {
      const config = {
        sharedProperties,
        layout: sharedProperties.includes('layout') ? layout : null,
      };

      const backgroundProperty = config.sharedProperties.find(p => p.type === 'background');
      if (backgroundProperty) {
        const background = backgroundProperty.value;
        if (background.startsWith('data:')) {
          try {
            const mimeType = background.split(';')[0].split('data:')[1];
            const ext = mimeTypeToExt(mimeType);
            const file = await urlToFile(background, `background-${getters.getLocalUser.id}.${ext}`, mimeType);
            const formData = new FormData();
            formData.append('formFile', file);
            const { promise } = await XperdriveLib.ImageUploadAnonymous(null, formData);
            const res = await promise;

            const newBackgroundUrl = res.data.result;
            backgroundImage = newBackgroundUrl;
            backgroundProperty.value = newBackgroundUrl;
          } catch (e) {
            dispatch('Notification/showToastNotification', { body: 'backgroundCouldntAppliedConference', config: { type: TOAST_TYPE.ERROR } }, { root: true });
            consoleError(e);
          }
        }
      }

      XperMeetLib.conference.roomConfig.setProperties(config);

      commit('CHANGE_LAYOUT_TYPE', layoutType);
      commit('SET_BACKGROUND', backgroundImage, { root: true });
      if (config?.sharedProperties && config.sharedProperties.some(prop => prop.type === 'background') && getters.getUsers.length > 1) {
        dispatch('Notification/sendChatNotification', { type: CHAT_NOTIFICATION_TYPES.USER_CHANGED_THE_WALLPAPER }, { root: true });
      }
    },
    updateRoomLayout({ getters }, { layout }) {
      if (getters.getSharedProperties.includes('layoutType')) {
        XperMeetLib.conference.roomConfig.setProperty('layout', layout);
      }
    },
    setFullScreen({ commit, getters }, { userId, fullScreen }) {
      if (getters.isFollowModeratorFullScreen) {
        const fullScreenUserId = fullScreen ? userId : null;
        XperMeetLib.conference.roomConfig.setProperty('fullScreenUserId', fullScreenUserId);
      }
      commit('SET_FULL_SCREEN', { userId, fullScreen });
    },
    setRoomBackgroundImage(context, { backgroundImage }) {
      consoleLog(backgroundImage);
      XperMeetLib.conference.roomConfig.setProperty(RoomConfigEnums.BACKGROUND_IMAGE, backgroundImage);
    },
    setPreferredVideoQuality({ commit }, payload) {
      commit('SET_PREFERRED_VIDEO_QUALITY', payload);
      XperMeetLib.conference.localUser.setReceiverVideoConstraint(payload);
      XperMeetLib.conference.localUser.setSenderVideoConstraint(payload);
    },
    generateRoomName() {
      return XperMeetLib.roomNameService.generate();
    },
    async validateRoomName({ dispatch }, payload) {
      const isPrivate = await dispatch('fetchRoomOwnerInfo', { roomName: payload });

      if (isPrivate) {
        return true;
      }

      const {
        data: { result: isValid },
      } = await XperMeetLib.roomNameService.validate(payload);

      return isValid;
    },
    sharedVideoUpdate({ state, getters }, { videoId, status, time, muted, volume, forceUpdate = false }) {
      if (status === 'start') {
        XperMeetLib.conference.sharedVideoUpdate(videoId, status, getters.getLocalUser.id);
      } else {
        const payload = JSON.parse(JSON.stringify(state.sharedVideo));
        if ((payload.value && payload.from === getters.getLocalUser.id) || forceUpdate) {
          videoId = videoId || payload.value;
          status = status || payload.status;
          const from = forceUpdate ? state.sharedVideo?.from : getters.getLocalUser.id;
          time = time || payload.time;
          muted = muted || payload.muted;
          volume = volume || payload.volume;

          XperMeetLib.conference.sharedVideoUpdate(videoId, status, from, time, volume, muted);
        }
      }
    },
    sendFileShareAnalyticData({ state, getters }, payload) {
      let sharedUserList = payload.users.map(id => {
        let user = getters.getUserById(id);
        return {
          UserName: user.displayName,
        };
      });
      let finalObj = {
        MeetingProsodyId: state.meetingId,
        RoomName: state.roomName,
        SharingUser: { UserName: getters.getLocalUser.displayName, KeycloakId: getters.getLocalUser.KeycloakId || null },
        SharedUserList: sharedUserList,
        FileKey: payload.file.fileKey,
        FileName: payload.file.fileName,
        Webinar: payload.shareWithWebinar || false,
      };
      ManageService.sendFileShareAnalyticData(finalObj);
    },
    fetchUsers({ }, payload = {}) {
      const { search } = payload;
      return XperMeetLib.keycloakManager.getUsers({ search });
    },
    grantModerator({ rootGetters, rootState, dispatch }, payload) {
      XperMeetLib.conference.grantModeratorRole(payload);
      dispatch(
        'Notification/sendRoomNotification',
        { text: 'becomeAModerator', userId: payload, type: ROOM_NOTIFICATION_TYPES.MODERATOR_ROLE },
        { root: true },
      );
      if ((rootGetters['Webinar/isWebinarRequestPending'] || rootGetters['Webinar/isWebinarStarted']) && rootState.Webinar.webinarStreamId) {
        XperMeetLib.conference.sendWebinarStreamId(rootState.Webinar.webinarStreamId, payload);
      }
    },
    revokeModerator({ dispatch }, payload) {
      dispatch(
        'Notification/sendRoomNotification',
        { text: 'revokeAModerator', userId: payload, type: ROOM_NOTIFICATION_TYPES.MODERATOR_ROLE },
        { root: true },
      );
      XperMeetLib.conference.revokeModeratorRole(payload);
      XperMeetLib.conference.sendWebinarStreamId('', payload);
    },
    onChatNotificationReceived({ state, getters, commit }, payload) {
      const { time, message, sender, data } = payload;
      commit('ADD_MESSAGE', {
        userId: getters.getLocalUser.id,
        id: state.messages.length + 1,
        date: time || new Date(),
        body: message,
        type: 'notification-message',
        sender: getters.getUserById(sender)?.displayName || i18n.t('user'),
        ...data,
      });
    },
    sendChatNotification({ dispatch }, payload) {
      //TODO REMOVE THIS FUNCTION
      dispatch('Notification/sendChatNotification', payload, { root: true });
    },
    shareFile({ getters, dispatch, commit, rootGetters }, payload) {
      const { files, shareWithWebinar, shareWithAllRoom, users } = payload;
      const localUser = getters.getLocalUser;
      // If webinar share enabled and webinar is started, send file to webinar users
      if (shareWithWebinar && rootGetters['Webinar/isWebinarStarted']) {
        dispatch('Webinar/sendMessage', { message: JSON.stringify(files) }, { root: true });
      }
      files.forEach(file => {
        dispatch(
          'Notification/sendRoomNotification',
          { text: 'sharedFile', userId: localUser.id, type: ROOM_NOTIFICATION_TYPES.FILE_SHARE, file },
          { root: true },
        );

        dispatch('sendFileShareAnalyticData', { file, shareWithWebinar, shareWithAllRoom, users });
      });

      // if public share
      if (shareWithAllRoom) {
        XperMeetLib.conference.sendMessage(JSON.stringify(files));
        return;
      }

      // send private file for all selected users
      users.forEach(userId => {
        XperMeetLib.conference.sendMessage(JSON.stringify(files), userId);
      });
      // add all files to message and file list once
      files.forEach(file => {
        commit('ADD_FILE', { ...file, nickName: localUser.displayName, private: true, userId: localUser.id });
      });
    },
    async fetchServerTime() {
      try {
        const { data } = await axios.get(`${window.XPER_CONFIG?.councilService}/noAuth/ballot/currentDate`, { public: true });
        return data;
      } catch (err) {
        return new Date().getTime();
      }
    },
    setServerTime({ commit }, payload) {
      commit('SET_SERVER_TIME', payload);
    },
    async setWebinarTitle({ state, dispatch }, payload) {
      const { prosody_meeting_id, title } = payload;
      try {
        await ManageService.setAndUpdateWebinarTitle({ prosody_meeting_id, title });
        dispatch('setRoomConfig', {
          ...state.roomConfig,
          [RoomConfigEnums.WEBINAR_TITLE]: title,
        });
      } catch (error) {
        consoleLog(error);
      }
    },
  },
  getters: {
    isFollowModeratorFullScreen(state) {
      return state.roomConfig[RoomConfigEnums.FOLLOW_MODERATOR_FULL_SCREEN];
    },
    getPrivateRoomName(state) {
      return state.user?.private_room_name;
    },
    lobbyAutoEnable(state, getters) {
      return window.customerConfig?.system?.lobbyAutoEnable || getters.getPrivateRoomName === state.roomName;
    },
    getPaginationEnabled(state) {
      return Vue.prototype.$customerConfig?.page?.pagination?.enabled && state.layoutType === 'dynamic';
    },
    getLastPage(state, getters) {
      return Math.ceil(state.users.length / getters.getPageSize) || 1;
    },
    getE2eeEnabled(state, getters) {
      return getters.getUsers.some(u => u.localUserE2ee);
    },
    getAnybodyLocalRecord(state, getters) {
      return getters.getUsers.some(u => u.localRecording);
    },
    getIsRecording(state, getters) {
      return getters.getRecordingDurations.length > 0;
    },
    getIsRecordPending({ recordingStatus }) {
      return recordingStatus === GLOBAL_ENUM.RECORDING_STATUS.PENDING;
    },
    getIsRemoteRecordDisable(state, getters) {
      const { remoteRecordStartedBy, getLocalUser } = getters;
      return remoteRecordStartedBy && remoteRecordStartedBy !== getLocalUser.id && !getLocalUser.isModerator;
    },
    getIsRemoteRecording(state) {
      return state.roomConfig[RoomConfigEnums.REMOTE_RECORD_STARTED_BY];
    },
    remoteRecordStartedBy(state) {
      const userId = state.users.find(u => u.KeycloakId === state.roomConfig[RoomConfigEnums.REMOTE_RECORD_STARTED_BY]);
      return userId?.id || null;
    },
    getRecordingDurations(state, getters) {
      const localRecorders = getters.getUsers.filter(u => u.localRecording);

      let remoteRecorder;
      if (getters.getIsRemoteRecording) {
        if (getters.remoteRecordStartedBy) {
          remoteRecorder = getters.getUserById(getters.remoteRecordStartedBy);
        } else {
          remoteRecorder = { id: getters.remoteRecordStartedBy, displayName: i18n.t('aParticipantWhoLeavingTheConference') };
        }
      }

      let recordList = localRecorders.map(user => ({
        startedAt: user.localRecordingAt,
        user: user,
      }));

      if (remoteRecorder) {
        recordList.unshift({
          startedAt: state.remoteRecordStartedAt,
          user: remoteRecorder,
        });
      }

      return recordList;
    },
    getLocalUser(state) {
      const user = state.users.find(u => u.type === 'local');

      if (!user) return false;

      const isModerator = user.role === 'moderator';
      const config = window.customerConfig;
      // TODO: modues system icinden alınacak kullanıcıya gelen role gore dinamik olarak verilecek.
      // config.system.getModules(user.role); gibi
      const modules = isModerator ? config?.system?.admin?.modules : config?.system?.modules;

      return {
        ...user,
        videoMuted: !user.hasCamera || user.videoMuted,
        modules: modules || {},
        isModerator: user.role === 'moderator',
      };
    },
    getUserById: (state, getters) => id => {
      return getters.getUsers.find(user => user.id === id);
    },
    getUnreadMessageCount(state) {
      return state.messages.filter(m => m.unread && m?.type !== 'notification-message').length;
    },
    getUsers(state) {
      return state.users.map(user => {
        return {
          ...user,
          hasAudioTrack: user.hasMicrophone,
          hasVideoTrack: user.hasCamera || user.screenSharing,
          isModerator: user.role === 'moderator',
        };
      });
    },
    getFullScreenUserId(state) {
      return state.users.find(u => u.fullScreen === true)?.id;
    },
    getRemoteUsers(state, getters) {
      return getters.getUsers.filter(u => u.type !== 'local');
    },
    getScreenSharingUserList(state) {
      return state.screenSharingUserList.sort(function (a, b) {
        if (a.startDate < b.startDate) {
          return -1;
        }
        if (a.startDate > b.startDate) {
          return 1;
        }
        return 0;
      });
    },
    getBackground(state, getters, rootState) {
      if (Vue.prototype.$customerConfig?.resources?.backgroundImg) {
        return Vue.prototype.$customerConfig?.resources?.backgroundImg
      }
      if (getters.getSharedProperty('background')) {
        return getters.getSharedProperty('background');
      } else {
        return rootState.background;
      }
    },
    getTheme(state, getters, rootState) {
      if (getters.getSharedProperty('theme')) {
        return getters.getSharedProperty('theme');
      } else {
        return rootState.theme;
      }
    },
    getRadius(state, getters, rootState) {
      if (getters.getSharedProperty('radius')) {
        return getters.getSharedProperty('radius');
      } else {
        return rootState.radius;
      }
    },
    getVideoShareAvailability(state) {
      return state.sharedVideo && !state.sharedVideo?.value;
    },
    getSharedProperty: state => type => {
      const sharedProps = state.roomConfig[RoomConfigEnums.SHARED_PROPERTIES] ?? [];

      if (type === 'jibriLayoutType') {
        return sharedProps.find(property => property.type === 'layoutType')?.value || '';
      }
      return sharedProps.find(property => property.type === type)?.value || '';
    },
    getSharedProperties(state) {
      const sharedProps = state.roomConfig[RoomConfigEnums.SHARED_PROPERTIES] ?? [];
      if (!sharedProps) {
        return [];
      }
      return sharedProps.map(property => property.type).filter(property => property !== 'radius');
    },
    getPageSize(state, getters) {
      return getters.getSharedProperty('pageSize') || state.pageSize;
    },
    remoteCameraControlSupported() {
      return Vue.prototype.$customerConfig?.system?.admin?.modules?.remoteCameraControl;
    },
    getDominantUserId(state, getters) {
      if (isMobile() && state.screenSharingUserList.length) {
        return state.screenSharingUserList[0].userId;
      }

      if (state.dominantUserId) {
        return state.dominantUserId;
      }

      if (getters.getRemoteUsers.length > 0) {
        const [user] = getters.getRemoteUsers;
        return user.id;
      }

      return null;
    },
    getAllAudioMutedState(state) {
      const isAllMuted = !state.users.some(u => !u.audioMuted);
      return isAllMuted || (state.allAudioMuted && isAllMuted);
    },
    getAllVideoMutedState(state) {
      const isAllMuted = !state.users.some(u => !u.videoMuted);
      return isAllMuted || (state.allVideoMuted && isAllMuted);
    },
    showARTab(state, getters) {
      return getters.getUsers.some(user => user.ARTrack);
    },
    getARFileList(state) {
      return state.roomConfig?.arFileList?.fileList || [];
    },
  },
};
